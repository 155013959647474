import Icon from '@mdi/react';
import { mdiFormatAlignLeft, mdiOpenInNew } from '@mdi/js';
import { useFarm } from "../contexts/FarmContext";

const ListBoxHeader = ({ title, url }) => {
    const { baseUrl } = useFarm();

    return (
        <div className="row mr-0 ml-0 col-12 pl-0 pr-0 pb-2 d-flex align-items-center justify-content-between">
            <div className="row mr-0 ml-0 d-flex align-items-center">
              <Icon path={mdiFormatAlignLeft} size={0.65} className="mr-1"/>
              <div className="font-bigger">{title}</div>
            </div>
            <a className="row mr-0 ml-0 d-flex align-items-center" href={`${baseUrl}${url}`}>
              <div className="font-small">View All</div>
              <Icon path={mdiOpenInNew} size={0.65} className="ml-1"/>
            </a>
        </div>
    );
};

export default ListBoxHeader