import universalCookie from "universal-cookie";
import axios from "axios";

const cookie = new universalCookie();

export function setJwtCookie(token) {
  cookie.set("access_token", token.access, {
    httpOnly: false,
    secure: true,
  });
  cookie.set("refresh_token", token.refresh, {
    httpOnly: false,
    secure: true,
  });
  if (token.expiration) {
    const [hours, minutes, seconds] = token.expiration.split(":");
    const totalSeconds = parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds);
    const expirationTime = new Date(Date.now() + totalSeconds * 1000);

    cookie.set("access_token_expiration_time", expirationTime, {
      httpOnly: false,
      secure: true,
    });
  }
}

export function clearJwtCookie() {
    cookie.set("access_token",'', {
        httpOnly: false,
        secure: true,
    });
    cookie.set("refresh_token", '', {
        httpOnly: false,
        secure: true,
    });
}

export function getAccessToken() {
  return cookie.get("access_token");
}

export function getRefreshToken() {
  return cookie.get("refresh_token");
}

export function getExpirationTime() {
  return cookie.get("access_token_expiration_time");
}

export function setAccessToken(token) {
  cookie.set("access_token", token, {
    httpOnly: false,
    secure: true,
  });
}

const axiosInstance = axios.create({
  headers: {
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const access_token = getAccessToken();
      const expirationTime = getExpirationTime();
      const refresh_token = getRefreshToken();

        if (access_token && new Date(Date.now()) > new Date(expirationTime)) {
            const response = await fetch(global.config.base_url+"/user/refresh_token/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    refresh: refresh_token,
                }),
            });

            const token = await response.json();
            console.log('expirate');
            console.log(token);
            if (token.code != 'token_not_valid') {
                setJwtCookie(token);
                config.headers.Authorization = `Bearer ${token.access}`;
            }
            else {
                config.headers.Authorization = `Bearer ${getAccessToken()}`;
            }
        }
        else {
            config.headers.Authorization = `Bearer ${getAccessToken()}`;
        }

      return config;
    } catch (error) {
      console.log(error.message);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
