import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { formatDatetime } from '../utils/formatDatetime';
import './Slider.css';

const ImageSlider = ({ items }) => {
  const CustomNextArrow = (props) => (
    <div
      className="custom-arrow next-arrow"
      onClick={props.onClick}
    >
      <Icon path={mdiChevronRight} size={1} className="mr-1"/>
    </div>
  );

  const CustomPrevArrow = (props) => (
    <div
      className="custom-arrow prev-arrow"
      onClick={props.onClick}
    >
      <Icon path={mdiChevronLeft} size={1} className="mr-1"/>
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <div className='slider-container w-100'>
      <Slider {...settings}>
        {items.map((item) => (
          <div className='slide' key={item.id}>
            <div className='row mr-3 ml-4'>
              {item.latest_log_url ? (
                <img
                  src={item.latest_log_url}
                  alt={item.title}
                  style={{ width: '100px', height: '150px', objectFit: 'cover', borderRadius: '10px' }}
                />
              ) : (
                <img
                  src={'images/no-image.png'}
                  alt={'No Log'}
                  style={{ width: '100px', height: '150px', objectFit: 'cover', borderRadius: '10px' }}
                />
              )}
              <div className="col pl-3 text-left">
                <div>{item.title}</div>
                <div className='text-muted pb-2'>{formatDatetime(item.last_active)}</div>
                <div className='grey-textarea'>{item.latest_log_description}</div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
