import GaugeChart from "../utils/GaugeChart";

function GaugeBox({ icon, title, value, mainColor }) {
    return (
        <div className="card">
            <div className="card-body">
                <div className="row mr-0 ml-0 d-flex flex-nowrap">
                    <div className="icon-box">
                        <img src={`icons/${icon}.svg`} alt="Icon" />
                    </div>
                    <div className="col-10 pl-2">
                        <div className='text-nowrap'>{title}</div>
                        <div>{value}</div>
                    </div>
                </div>
                
                <div className="chart-textarea mt-2">
                    <GaugeChart value={value} mainColor={mainColor}/>
                </div>
            </div>
        </div>
    );
}

export default GaugeBox;
