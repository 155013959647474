import React from 'react';
import Icon from '@mdi/react';
import { mdiCheckboxMarkedCircle, mdiWifiStrength3 } from '@mdi/js';
import "./MissionBox.css";

function MissionBox({ missions, stations }) {

  return (
    <div className="col-3 col-sm-3 pb-3 pb-sm-0 mission position-absolute">
        <div className="card mt-4 text-white mission-box">
            <div className="card-body">
                <div className="mr-0 ml-0 mb-4">
                    <div className="font-bigger mb-1">Scheduled Missions</div>
                    {missions.length > 0 ? (
                        missions.map((item, index) => (
                            <div key={index} className="d-flex align-items-center text-white mb-1">
                            <Icon path={mdiCheckboxMarkedCircle} size={0.5} className={`mr-2 ${item.active ? 'color-main' : ''}`}/>
                            <span>{item.name} {item.date}</span>
                            </div>
                        ))
                    ) : (
                    <div className="text-white">There is no scheduled mission.</div>
                    )}
                </div>
                <div className="mr-0 ml-0">
                    <div className="font-bigger mb-1">IoT Stations</div>
                    {stations.length > 0 ? (
                        stations.map((item, index) => (
                            <div key={index} className="d-flex align-items-center text-white mb-1">
                            <Icon path={mdiWifiStrength3} size={0.5} className={`mr-2 ${item.is_active ? 'color-main' : ''}`}/>
                            <div>{item.name}</div>
                            </div>
                        ))
                    ) : (
                    <div className="text-white">There is no IoT station.</div>
                    )}
                </div>
            </div>
        </div>
    </div>
  );
}

export default MissionBox;
