import React from 'react';
import ImageSlider from '../utils/Slider';

function GalleryBox({ icon, title, count, items }) {
  return (
    <div className="card">
      <div className="card-body pb-0">
        <div className="row mr-0 ml-0 d-flex flex-nowrap">
          <div className="icon-box">
            <img src={`icons/${icon}.svg`} alt="Icon" />
          </div>
          <div className="col-10 pl-2">
            <div className='text-nowrap'>{title}</div>
            <div>{count}</div>
          </div>
        </div>

        <div className="row ml-0 mr-0 mt-2">
          {items.length>0 ? (
            <ImageSlider items={items} />
          ) : (
            <div className='custom-textarea text-muted font-italic'>There is no data.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default GalleryBox;
