import React from 'react';
import FarmBlockDropdown from './FarmBlockDropdown';
import { Link } from 'react-router-dom';
import { useFarm } from "../contexts/FarmContext";

const Appbar = ({ onSelectBlock }) => {
  const { baseUrl } = useFarm();

  return (
  <div className="row mr-0 ml-0" style={{ width: "100%", height: "60px", backgroundColor: "#52cea7", position: "fixed", zIndex: "999" }}>
    <Link to={`${baseUrl}dashboard/`}>
      <img src="logo-white.png" alt="Logo" style={{ height: '30px', margin: '15px' }}/>
    </Link>
    <FarmBlockDropdown onSelectBlock={onSelectBlock} />
  </div>
  );
};

export default Appbar;
