import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const EmptyDonutChart = () => {
  const [options, setOptions] = useState({
    chart: {
      type: 'donut',
    },
    labels: [],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    events: {
      click: undefined,
    },
    colors: ['#CCCCCC'],
    states: {
      hover: {
        filter: "none",
      },
    },
    tooltip: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 576,
        options: {
          chart: {
            width: '70%',
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  });

  const series = [1];

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const responsiveConfig = options.responsive || [];
  
      let updatedOptions = { ...options };
  
      for (const config of responsiveConfig) {
        if (
          windowWidth >= config.breakpoint &&
          windowWidth <= (config.nextBreakpoint || Number.MAX_VALUE)
        ) {
          updatedOptions = { ...updatedOptions, ...config.options };
          break; // Stop processing further configurations once a matching one is found
        }
      }
  
      setOptions(updatedOptions);
    };
  
    handleResize();
  
    window.addEventListener('resize', handleResize);
  
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <ReactApexChart options={options} series={series} type="donut" style={{ display: 'flex', justifyContent: 'center' }} />;
};

export default EmptyDonutChart;
