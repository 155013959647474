import WeatherBox from "../../components/WeatherBox";
import GaugeBox from "../../components/GaugeBox";
import ChartBox from "../../components/ChartBox";
import Icon from '@mdi/react';
import { mdiPoll } from '@mdi/js';
import InfoBox from "./InfoBox";
import React, { useState, useEffect } from "react";
import axios from "../../auth/auth";
import { useFarm } from  "../../contexts/FarmContext";

function Summary() {
  const { selectedFarm} = useFarm();
  const [ currentWeather, setCurrentWeather ] = useState([]);
  const [ forecastWeather, setForecastWeather ] = useState([]);
  const [ stressLevel, setStressLevel ] = useState([]);
  const [ inventory, setInventory ] = useState([]);
  const [ totalTree, setTotalTree ] = useState([]);
  const [ totalHealthy, setTotalHealthy ] = useState([]);
  const [ totalAbnormal, setTotalAbnormal ] = useState([]);
  const [ totalBreed, setTotalBreed ] = useState([]);
  const [ totalSymptom, setTotalSymptom ] = useState([]);
  const [ breeds, setBreeds ] = useState([]);
  const [ symptoms, setSymptoms ] = useState([]);
  const isTreeEmpty = totalTree === 0;
  const isBreedEmpty = breeds.length === 0;
  const isSymptomEmpty = symptoms.length === 0;

  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const response = await axios.get(global.config.base_url+'dashboard/api/weather/'+selectedFarm.id+'/');

        if (response.status === 200) {
          const data = response.data;

          setCurrentWeather(data[0]);
          setForecastWeather(data.slice(1));
        }
      } catch (error) {
        console.error("Error fetching weather:", error);
      }
    };

    const fetchSummary = async () => {
      try {
        const response = await axios.get(global.config.base_url+'dashboard/api/summary/'+selectedFarm.id+'/');

        if (response.status === 200) {
          const data = response.data;

          setStressLevel(data.stress_level);
          setInventory(data.inventory);

          setTotalTree(data.total_tree);
          setTotalHealthy(data.total_healthy);
          setTotalAbnormal(data.total_abnormal);
          setTotalBreed(data.total_breed);
          setTotalSymptom(data.total_symptom);
          setBreeds(data.breeds);
          setSymptoms(data.symptoms);
        }
      } catch (error) {
        console.error("Error fetching summary:", error);
      }
    };

    fetchWeather();
    fetchSummary();
  }, [selectedFarm]);

  const trees = {
    labels: ['Healthy', 'Abnormal'],
    series: [totalHealthy, totalAbnormal],
  };

  const breedLabels = [];
  const breedSeries = [];

  breeds.forEach((breed) => {
    breedLabels.push(breed.name);
    breedSeries.push(breed.count);
  });

  const breedData = {
    labels: breedLabels,
    series: breedSeries,
  };

  const symptomLabels = [];
  const symptomSeries = [];

  symptoms.forEach((symptom) => {
    symptomLabels.push(symptom.name);
    symptomSeries.push(symptom.count);
  });

  const symptomData = {
    labels: symptomLabels,
    series: symptomSeries,
  };

  return (
    <div className="pt-3">
      <div className="col-12 pb-3 pb-sm-4">
        <WeatherBox icon={currentWeather.icon} weatherDescription={currentWeather.weather_description} temperature={currentWeather.temperature} forecasts={forecastWeather}/>
      </div>

      <div className="row mr-0 ml-0 col-12 pb-2">
        <Icon path={mdiPoll} size={0.8} className="mr-1"/>
        <div className="font-bigger">Farm Status</div>
      </div>

      <div className="row mr-0 ml-0 pr-0 pl-0 col-12 pb-1 pb-sm-4">
        <div className="col-12 col-sm-4 pb-3 pb-sm-0">
          <InfoBox />
        </div>
        <div className="col-12 col-sm-4 pb-3 pb-sm-0">
          <GaugeBox icon={'stress'} title={'Stress Level'} value={stressLevel} />
        </div>
        <div className="col-12 col-sm-4 pb-3 pb-sm-0">
          <GaugeBox icon={'inventory'} title={'Inventory'} value={inventory} mainColor={'#52cea7'}/>
        </div>
      </div>

      <div className="row mr-0 ml-0 col-12 pb-2">
        <Icon path={mdiPoll} size={0.8} className="mr-1"/>
        <div className="font-bigger">Tree Insights</div>
      </div>

      <div className="row mr-0 ml-0 pr-0 pl-0 col-12 pb-4">
        <div className="col-12 col-sm-4 pb-3 pb-sm-0">
          {isSymptomEmpty ? (
            <ChartBox icon={'symptom'} title={'Symptoms'} count={totalSymptom} data={null} />
          ) : (
            <ChartBox icon={'symptom'} title={'Symptoms'} count={totalSymptom} data={symptomData} />
          )}
        </div>
        <div className="col-12 col-sm-4 pb-3 pb-sm-0">
          {isTreeEmpty ? (
            <ChartBox icon={'tree'} title={'Total Tree'} count={totalTree} data={null} />
          ) : (
            <ChartBox icon={'tree'} title={'Total Tree'} count={totalTree} data={trees} />
          )}
        </div>
        <div className="col-12 col-sm-4 pb-3 pb-sm-0">
          {isBreedEmpty ? (
            <ChartBox icon={'breed'} title={'Total Breed'} count={totalBreed} data={null} />
          ) : (
            <ChartBox icon={'breed'} title={'Total Breed'} count={totalBreed} data={breedData} />
          )}
        </div>
      </div>
    </div>
  );
}

export default Summary;
