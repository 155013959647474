import React, { useState, useEffect } from "react";
import Map from "../dashboard/Map";
import AppBar from "../../components/Appbar";
import CurrentData from "./CurrentData";
import Summary from "./Summary";
import OverlayInfo from "./OverlayInfo";

function Dashboard() {
  const [selectedBlock, setSelectedBlock] = useState(null);

  const onSelectBlock = (block) => {
    setSelectedBlock(block);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <AppBar onSelectBlock={onSelectBlock} />
      <div className="row mr-0 ml-0" style={{ marginTop: "60px" }}>
        <div className="col-12 col-xl-7 pr-0 pl-0 position-relative">
          <OverlayInfo />
          <Map selectedBlock={selectedBlock} />
          <CurrentData />
        </div>
        
        <div className="col-12 col-xl-5 pr-0 pl-0">
          <Summary />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
