import React from 'react';
import { formatClockDate, formatClockTime } from '../utils/formatDatetime';
import "./RealTimeClock.css";

function RealTimeClock({currentTime}) {
  
  return (
    <div className="card mt-3 clock-bg">
      <div className="card-body">
          <div className="d-flex justify-content-between">
            <div>
              {formatClockDate(currentTime)}
            </div>
            <div>
              {formatClockTime(currentTime)}
            </div>
          </div>
      </div>
    </div>
  );
};

export default RealTimeClock;