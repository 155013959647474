import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

const GaugeChart = ({ value, mainColor }) => {
  const chartContainerRef = useRef(null);
  const colorMain = mainColor || '#0A79BD';

  useEffect(() => {
    const chartId = `gauge-chart-${Math.floor(Math.random() * 1000000)}`;
    chartContainerRef.current.id = chartId;

    const chart = echarts.init(chartContainerRef.current);

    const option = {
      series: [
        {
          type: 'gauge',
          detail: {
            formatter: `\n${value==0?'N/A':value}`,
            fontSize: 16,
          },
          data: [{ value, name: '' }],
          axisLabel: {
            fontSize: 0,
          },
          axisLine: {
            lineStyle: {
              color: [
                [0, colorMain],
                [value / 100, colorMain],
                [value / 100, "#CCCCCC"],
                [1, "#CCCCCC"],
              ],
            },
          },
          pointer: {
            itemStyle: {
              color: colorMain,
            },
          },
        },
      ],
    };

    chart.setOption(option);

    return () => chart.dispose();
  }, [value, mainColor]);

  return <div ref={chartContainerRef} style={{ width: '100%', height: '160px' }} />;
};

export default GaugeChart;
