import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { setJwtCookie } from "./auth/auth";
import { clearJwtCookie } from "./auth/auth";
import './config';

const root = ReactDOM.createRoot(document.getElementById('root'));
var socket = new WebSocket(global.config.websocket_base_url + "auth/user/");

socket.onopen = () => {
    console.log("WebSocket connection established.");
};
socket.onmessage = (event) => {
    const message = JSON.parse(event.data)
    console.log(message.status);
    if (message.status === 'authorised') {
        setJwtCookie(message);
        root.render(
            <React.StrictMode>
                <Router>
                    <App />
                </Router>
            </React.StrictMode>
        );
    }
    if (message.status === 'unauthorised') {
        clearJwtCookie();
        const djangoUrl = global.config.websocket_base_url+"logout/";
        window.location.replace(djangoUrl);
    }

}


reportWebVitals();
