import React, { useEffect, useRef, useState } from "react";
import { useFarm } from  "../../contexts/FarmContext";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import axios from "../../auth/auth";
import * as turf from '@turf/turf';
import './Map.css'
import { showTreeStatus } from "../../utils/showTreeStatus";

const Map = ({ selectedBlock }) => {
  const { selectedFarm, baseUrl } = useFarm();
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [ trees, setTrees ] = useState([]);

  useEffect(() => {
    const fetchTrees = async () => {
      try {
        const response = await axios.get(global.config.base_url+'tree/api/slot/?farm='+selectedFarm.id+'&paired=true');

        if (response.status === 200) {
          const data = response.data;
          setTrees(data.results);
        }
      } catch (error) {
        console.error("Error fetching trees:", error);
      }
    };

    if (selectedFarm) {
      fetchTrees();
    }
  }, [selectedFarm])

  useEffect(() => {
    const fetchBoundary = async () => {
      try {
        const response = await axios.get(global.config.base_url+'dashboard/api/farm/boundary/'+selectedFarm.id+'/');

        if (response.status === 200) {
          const coordinates = response.data;
          
          document.getElementById("map").innerHTML = "";

          mapboxgl.accessToken =
            "pk.eyJ1IjoibGV2aWNoZXciLCJhIjoiY2xxM2lxOWxtMGNuMDJpb2JrdnI1MXhnOCJ9.sqVHkvAIaplu91KGLXsL-g";

          const map = new mapboxgl.Map({
            container: "map",
            style: "mapbox://styles/mapbox/light-v11",
            pitch: 55,
            antialias: true,
            projection: "globe",
            attributionControl: false,
            scrollZoom: false,
            dragPan: false,
            touchZoomRotate: false,
            doubleClickZoom: false,

            fadeDuration: 0,
            transitionDuration: 0,
          });

          const farmBoundary = {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Polygon",
                  coordinates: [coordinates],
                },
              },
            ],
          };

          const bbox = turf.bbox(farmBoundary);

          map.on("load", () => {
            const layers = map.getStyle().layers;

            // ---------------------REMOVE WATERMARK-------------------------
            layers.forEach((layer) => {
              if (layer.type === "symbol" && layer.layout["text-field"]) {
                map.setLayoutProperty(layer.id, "visibility", "none");
              }
            });

            // ---------------------ADD GRASS TEXTURE-------------------------
            map.loadImage("/images/grass.jpg", (error, image) => {
              if (error) throw error;
              map.addImage("grass-texture", image);
            });

            map.addLayer({
              id: "farm-layer",
              type: "fill",
              source: {
                type: "geojson",
                data: farmBoundary,
              },
              paint: {
                "fill-pattern": "grass-texture",
                "fill-opacity": 1,
              },
            });

            // ---------------------COVER OUTSIDE BOUNDARY-------------------------
            map.addLayer({
              id: "outside-layer",
              type: "fill",
              source: {
                type: "geojson",
                data: {
                  type: "Feature",
                  geometry: {
                    type: "Polygon",
                    coordinates: [
                      [
                        [-180, -90],
                        [-180, 90],
                        [180, 90],
                        [180, -90],
                        [-180, -90],
                      ],
                      ...farmBoundary.features[0].geometry.coordinates,
                    ],
                  },
                },
              },
              paint: {
                "fill-color": "#f8f8f8",
                "fill-opacity": 1,
              },
            });
          
            // ---------------------ADD TERRAIN-------------------------
            map.addSource('mapbox-dem', {
              'type': 'raster-dem',
              'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
              'tileSize': 512,
              'maxzoom': 14,
            });
            map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 2.5 });

            // ---------------------FIT BOUNDARY-------------------------
            map.fitBounds(bbox, {
              duration: 0,
              pitch: 45,
            });
            map.panBy([0, 40]);

            // ---------------------FARM BLOCK-------------------------
            if (selectedBlock!=null) {
              const coords = [...selectedBlock.coordinates];
              const coordinates = coords.map(coord => [coord[1], coord[0]]);
              coordinates.push(coordinates[0]);
              
              map.addLayer({
                id: "block",
                type: "line",
                source: {
                  type: "geojson",
                  data: {
                    type: "Feature",
                    geometry: {
                      type: "Polygon",
                      coordinates: [coordinates],
                    },
                  },
                },
                paint: {
                  "line-color": selectedBlock.color,
                  "line-width": 2,
                },
                layout: {
                  visibility: "visible",
                }
              });
            } else {
              selectedFarm.blocks.forEach((block) => {
                const coords = [...block.coordinates];
                const coordinates = coords.map(coord => [coord[1], coord[0]]);
                coordinates.push(coordinates[0]);
                
                map.addLayer({
                  id: `block-${block.id}`,
                  type: "line",
                  source: {
                    type: "geojson",
                    data: {
                      type: "Feature",
                      geometry: {
                        type: "Polygon",
                        coordinates: [coordinates],
                      },
                    },
                  },
                  paint: {
                    "line-color": block.color,
                    "line-width": 2,
                  },
                });
              });
            }

            // ---------------------ADD TREE-------------------------
            // Create a popup, but don't add it to the map yet.
            const popup = new mapboxgl.Popup({
              closeButton: false,
              // closeOnClick: false
            });

            // Initialize a variable to track popup state
            let isPopupOpen = false;

            // Event to show popup on hover or click
            const showPopup = (e) => {
              // Copy coordinates array
              const coordinates = e.features[0].geometry.coordinates.slice();
              const description = e.features[0].properties.description;

              // Ensure that if the map is zoomed out such that multiple copies of the feature are visible, the popup appears over the copy being pointed to
              while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
              }

              // Populate the popup and set its coordinates based on the feature found
              popup.setLngLat(coordinates).setHTML(description).addTo(map);
            };

            trees.forEach((tree, index) => {
              map.addLayer({
                id: `tree-layer-${index}`,
                type: "circle",
                source: {
                  type: "geojson",
                  data: {
                    type: "Feature",
                    'properties': {
                      'description':
                        '<strong>Tree Name: </strong> ' + tree.name + '<br>' +
                        '<strong>Status: </strong> ' + showTreeStatus(tree.status) + '<br>' +
                        '<strong>Breed: </strong> ' + (tree.current_tree && tree.current_tree.breed && tree.current_tree.breed.name ? tree.current_tree.breed.name : 'N/A') + '<br>' +
                        '<a href="' + baseUrl + 'tree/analytics/' + tree.name + '/" target="_blank">View Tree</a>'
                    },
                    geometry: {
                      type: "Point",
                      coordinates: [tree.longitude, tree.latitude],
                    },
                  },
                },
                paint: {
                  "circle-radius": 5, 
                  "circle-color": tree.status===1?"#52cea7":"#BD090A",
                  "circle-opacity": 1,
                },
              });

              map.on('mouseenter', `tree-layer-${index}`, (e) => {
                // Change the cursor style as a UI indicator.
                map.getCanvas().style.cursor = 'pointer';
                showPopup(e);
              });
                
              map.on('mouseleave', `tree-layer-${index}`, () => {
                // Only remove the popup if it wasn't clicked
                if (!isPopupOpen) {
                  map.getCanvas().style.cursor = '';
                  popup.remove();
                }
                // Reset popup state
                isPopupOpen = false;
              });

              map.on('click', `tree-layer-${index}`, (e) => {
                showPopup(e);
                // Update popup state
                isPopupOpen = true;
              });
            });
          });

          return () => map.remove();
        }
      } catch (error) {
        console.error("Error fetching farm boundary points:", error);
      }
    };

    if (selectedFarm&&trees) {
      fetchBoundary();
    }
  }, [selectedFarm, selectedBlock, trees]);
  return <div id="map" style={{ padding: "0px", width: "100%", height: "60vh"}} />;
};

export default Map;
