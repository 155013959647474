import DonutChart from "../utils/DonutChart";
import EmptyDonutChart from "../utils/EmptyDonutChart";
import "./ChartBox.css";

function ChartBox({ icon, title, count, data }) {
    return (
        <div className="card">
            <div className="card-body">
                <div className="row mr-0 ml-0 d-flex flex-nowrap">
                    <div className="icon-box">
                        <img src={`icons/${icon}.svg`} alt="Icon" />
                    </div>
                    <div className="col-10 pl-2">
                        <div className='text-nowrap'>{title}</div>
                        <div>{count}</div>
                    </div>
                </div>
                
                <div className="chart-textarea mt-2 d-flex justify-content-center">
                    {data!=null ? (
                        <div className="donut-chart">
                            <DonutChart data={data} />
                            <div className="mt-2 pr-2">
                                {data.labels.map((label, index) => (
                                    <div key={index} className="row mr-0 ml-0 flex-nowrap">
                                        <div className="col-9 d-flex">
                                            <span className="text-nowrap">{label}</span>
                                        </div>
                                        <div className="col-3 pl-0 pr-0 text-right">
                                            {data.series[index]}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="donut-chart">
                            <EmptyDonutChart />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ChartBox;
