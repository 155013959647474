import ListBox from "../../components/ListBox";
import GalleryBox from "../../components/GalleryBox";
import ListBoxHeader from "../../components/ListBoxHeader";
import React, { useState, useEffect } from "react";
import axios from "../../auth/auth";
import { useFarm } from  "../../contexts/FarmContext";

function CurrentData() {
  const { selectedFarm } = useFarm();
  const [ taskCount, setTaskCount ] = useState(0);
  const [ tasks, setTasks ] = useState([]);
  const [ workerCount, setWorkerCount ] = useState(0);
  const [ workers, setWorkers ] = useState([]);
  const [ logbookCount, setLogbookCount ] = useState(0);
  const [ logbooks, setLogbooks ] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(global.config.base_url+'dashboard/api/current/data/'+selectedFarm.id+'/');

        if (response.status === 200) {
          const data = response.data;

          setTaskCount(data.task_count);
          setTasks(data.tasks);
          setWorkerCount(data.worker_count);
          setWorkers(data.workers);
          setLogbookCount(data.logbook_count);
          setLogbooks(data.logbooks);
        }
      } catch (error) {
        console.error("Error fetching current data:", error);
      }
    };

    fetchData();
  }, [selectedFarm]);

  return (
    <div className="row mr-0 ml-0 pr-0 pl-0 col-12 mb-1 mb-xl-3">
      <div className="row mr-0 ml-0 pr-0 pl-0 col-12 col-lg-6 pb-3 pb-lg-0">
        <div className="col-12 col-sm-6 pb-3 pb-sm-0">
          <ListBoxHeader title={'My Tasks'} url={'task/list/to-do/'} />
          <ListBox icon={'task'} title={'Task To Do'} items={tasks} count={taskCount} />
        </div>
        <div className="col-12 col-sm-6">
          <ListBoxHeader title={'My Workers'} url={'worker/attendance/'} />
          <ListBox icon={'worker'} title={'Currently Working'} items={workers} count={workerCount} />
        </div>
      </div>

      <div className="col-12 col-lg-6">
        <ListBoxHeader title={'My Logbooks'} url={'logbook/'} />
        <GalleryBox key={JSON.stringify(logbooks)} icon={'logbook'} title={'Active Logbook'} items={logbooks} count={logbookCount} />
      </div>
    </div>
  );
}

export default CurrentData;
  