import React from 'react';
import { formatTime } from '../utils/formatDatetime';
import './WeatherBox.css';

function WeatherBox({ icon, weatherDescription, temperature, forecasts}) {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row mr-0 ml-0 justify-content-between flex-nowrap">
          <div className="row mr-0 ml-0 align-items-center">
            <img src={`weather/${icon}.png`} alt="Icon" className="weather-icon" />
            <div className="pl-2 align-self-center">
              <div className="font-weight-bold">Current Weather</div>
              <div className="text-capitalize">{weatherDescription}</div>
              <div>{temperature}°C</div>
            </div>
          </div>

          <div className="row mr-0 ml-0">
            {forecasts.map((forecast, index) => (
              <div key={index} className='pl-2'>
                <div className="font-weight-bold text-center">{formatTime(forecast.datetime)}</div>
                <img src={`weather/${forecast.icon}.png`} alt="Icon" className="weather-icon" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default WeatherBox;
