import React from 'react';
import { formatTime } from '../utils/formatDatetime';
import { useFarm } from "../contexts/FarmContext";

function ListBox({ icon, title, count, items }) {
  const { baseUrl } = useFarm();

  return (
    <div className="card">
      <div className="card-body">
        <div className="row mr-0 ml-0 d-flex flex-nowrap">
          <div className="icon-box">
            <img src={`icons/${icon}.svg`} alt="Icon" />
          </div>
          <div className="col-10 pl-2">
            <div className='text-nowrap'>{title}</div>
            <div>{count}</div>
          </div>
        </div>

        <div className="custom-textarea mt-2">
          {items.length>0 ? 
            items.map((item, index) => (
              <p key={index} className="p-1 mb-0">
                {item.start_datetime && (
                  <span className="pr-2">{formatTime(item.start_datetime)}</span>
                )}
                {item.url ? (
                  <a href={`${baseUrl}${item.url}`}>
                    {item.worker.name}
                  </a>
                ) : (
                  <span>{item.worker.name}</span>
                )}
              </p>
            )) : (
            <div className='text-muted font-italic'>There is no data.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ListBox;
