import React, { useEffect } from "react";

import Dashboard from "./pages/dashboard/Dashboard";
import { FarmProvider } from "./contexts/FarmContext";

function App() {
  //useEffect(() => {
  //  const fetchData = async () => {
  //    try {
  //      //const response = await fetch("http://127.0.0.1:8000/user/get_token_pair/", {
  //      //  method: "POST",
  //      //  headers: {
  //      //    "Content-Type": "application/json",
  //      //  },
  //      //  body: JSON.stringify({
  //       //   email: "jiafutey123@gmail.com",
  //      //    password: "Zxcv@1234",
  //      //  }),
  //     // });

  //      //const token = await response.json();
          
        
  //    } catch (error) {
  //      console.error("Error fetching token pair:", error);
  //    }
  //  };

  //  fetchData();
  //}, []);

  return (
    <FarmProvider>
      <div style={{ backgroundColor: "#f8f8f8", minHeight: "100vh" }}>
        <Dashboard />
      </div>
    </FarmProvider>
  );
}

export default App;
