import React, { useState, useEffect } from 'react';
import MissionBox from "../../components/MissionBox";
import RealTimeClock from "../../components/RealTimeClock";
import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';
import { useFarm } from  "../../contexts/FarmContext";
import axios from "../../auth/auth";

function OverlayInfo() {
  const { selectedFarm} = useFarm();
  const [ missions, setMissions ] = useState({});
  const [ stations, setStations ] = useState({});
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update every second

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const fetchMissions = async () => {
      try {
        const response = await axios.get(global.config.base_url+'dashboard/api/mission/'+selectedFarm.id+'/');

        if (response.status === 200) {
          const data = response.data;
          setMissions(data);
        }
      } catch (error) {
        console.error("Error fetching mission:", error);
      }
    };

    const fetchStations = async () => {
      try {
        const response = await axios.get(global.config.base_url+'sensor_station/api/?farm='+selectedFarm.id);

        if (response.status === 200) {
          const data = response.data;
          setStations(data.results);
        }
      } catch (error) {
        console.error("Error fetching station:", error);
      }
    };

    fetchMissions();
    fetchStations();
  }, [selectedFarm]);

  return (
    <div>
      <div className="col-12 col-sm-5 pb-3 pb-sm-0 clock position-absolute">
        <div className="col-12 col-sm-10 pl-0">
          <RealTimeClock currentTime={currentTime} />
        </div>
        <div className="mr-0 ml-0 mt-1">
          <div className="d-flex align-items-center text-muted">
            <Icon path={mdiInformation} size={0.65} className="mr-1 ml-2"/>
            <span><i>Orthomap last updated at {selectedFarm.latest_ortho_date??'-'}</i></span>
          </div>
        </div>
      </div>
      <MissionBox missions={missions} stations={stations} />
    </div>
  );
}

export default OverlayInfo;