export const formatDatetime = (datetime) => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'UTC', // Set timezone to UTC to avoid localization because already convert to local in api
  };

  const formattedDatetime = new Date(datetime).toLocaleDateString('en-US', options);
  return formattedDatetime;
};

export const formatDate = (datetime) => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    timeZone: 'UTC',
  };

  const formattedDate = new Date(datetime).toLocaleDateString('en-US', options);
  return formattedDate;
};

export const formatTime = (datetime) => {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  
  const formattedTime = new Date(datetime).toLocaleTimeString('en-US', options);
  return formattedTime;
};

export const formatClockDate = (datetime) => {
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  };
  const formattedDate = new Date(datetime).toLocaleDateString('en-US', options);
  return formattedDate;
};

export const formatClockTime = (datetime) => {
    const options = { 
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
    const formattedTime = new Date(datetime).toLocaleTimeString('en-US', options);
    return formattedTime;
};
