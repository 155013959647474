import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../auth/auth';
import Loader from '../utils/Loader';

const FarmContext = createContext();

export const FarmProvider = ({ children }) => {
  const [selectedFarm, setSelectedFarm] = useState(null);
  const [farms, setFarms] = useState([]);
  const [demoFarm, setDemoFarm] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDemo, setIsDemo] = useState(false);
  const baseUrl = 'http://127.0.0.1:8000/';

  useEffect(() => {
    const fetchDemo = async () => {
      try {
        const response = await axios.get(global.config.base_url+'dashboard/api/demo/farm/');
        const demoFarm = response.data['demo_farm'];

        setDemoFarm(demoFarm);

        if (!selectedFarm) {
          setSelectedFarm(demoFarm);
          setIsDemo(true);
        }

      } catch (error) {
        console.error('Error fetching demo farm:', error);
      }
    };

    fetchDemo();
  }, []);

  useEffect(() => {
    const fetchFarms = async () => {
      try {
        const response = await axios.get(global.config.base_url+'api/farms/');
        const fetchedFarms = response.data['results'];

        setFarms(fetchedFarms);

        if (!selectedFarm && fetchedFarms.length > 0) {
          setSelectedFarm(fetchedFarms[fetchedFarms.length - 1]);
          setIsDemo(false);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching farms:', error);
      }
    };

    if (!selectedFarm) {
      fetchFarms();
    }
  }, []);

    if (loading) {
        return <Loader />;
    } else {
        return (
            <FarmContext.Provider value={{ selectedFarm, setSelectedFarm, farms, baseUrl, demoFarm, isDemo, setIsDemo }}>
                {children}
            </FarmContext.Provider>
        );
    }

  
};

export const useFarm = () => {
  const context = useContext(FarmContext);
  if (!context) {
    throw new Error('useFarm must be used within a FarmProvider');
  }
  return context;
};
