import React from 'react';
import Icon from '@mdi/react';
import { mdiTrendingUp, mdiTrendingDown } from '@mdi/js';

const Badge = ({ value, increase }) => {
  return (
    <div className={`badge font-smaller d-flex align-items-center ${increase ? 'badge-success bg-main' : 'badge-danger bg-danger'}`}>
      {increase ? <Icon path={mdiTrendingUp} size={0.5} className="mr-1"/> : <Icon path={mdiTrendingDown} size={0.5} className="mr-1"/>}
      {value}
    </div>
  );
};

export default Badge;
